import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        component: () => import('./views/MainView.vue'),
        meta: {
            title: 'Gender Bias Simulator '
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    next();

    if(to.meta.title){
        document.title = to.meta.title
        console.log(this.expand)
    }
});

export default router
