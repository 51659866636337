import { createStore } from 'vuex'

let currentUser;
let dragObject;
let dragList;
let tmModel;

export default createStore({
    state: {
        currentUser,
        dragObject,
        dragList,
        tmModel
    },
    getters: {
        getCurrentUser(state) {
            return state.currentUser;
        },
        getDragObject(state){
            return state.dragObject;
        },
        getDragList(state){
            return state.dragList;
        },
        gettmModel(state){
            return state.tmModel;
        }
    },
    mutations: {
        user(state, user){
            state.currentUser = user;
        },
        dragObject(state, object){
            state.dragObject = object;
        },
        dragList(state, list){
            state.dragList = list;
        },
        tmModel(state, tmModel){
            state.tmModel = tmModel;
        }
    },
    actions: {
    },
    modules: {
    }
})
