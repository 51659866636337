import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store.js';
import Vue3Tour from 'vue3-tour'

import 'vue3-tour/dist/vue3-tour.css'


let app;

if (!app) {
    app = createApp(App).use(store).use(router).use(Vue3Tour).mount('#app')
}
